import React from 'react'
import { getCurrentDate } from '../../Functions/GetDate'
function Dashboard () {
  const lg = localStorage.getItem('log')
  if (lg !== 'yes') {
    window.location.href = '/login'
  }
  const Name = localStorage.getItem('AuthUserRealName')
  const Poruka = localStorage.getItem('NoteToDriver')
  function handleSignOut () {
    localStorage.clear()
    window.location.href = '/login'
  }
  const danas = getCurrentDate(0)
  const sutra = getCurrentDate(1)
  const prekosutra = getCurrentDate(2)
  return (
    <div>
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="display-4">Welcome {Name}</h1>
          <p className="lead">Message: {Poruka}</p>
        </div>
      </div>
      <div className="d-grid gap-2 col-6 mx-auto">
        <a href={'/daytransfers/' + danas} className="btn btn-primary" type="button">
          Todays transfers
        </a>
        <a href={'/daytransfers/' + sutra} className="btn btn-primary" type="button">
          Tomorrows transfers
        </a>
        <a href={'/daytransfers/' + prekosutra} className="btn btn-primary" type="button">
          In two days
        </a>
        <a className="btn btn-primary" type="button">
          All Transfers
        </a>
        <a className="btn btn-primary" type="button">
          Expenses
        </a>
        <a className="btn btn-primary" type="button">
          Calculator
        </a>
        <a className="btn btn-primary" type="button">
          Messages
        </a>
        <a className="btn btn-primary" type="button">
          SignOut
        </a>
        <button
          onClick={handleSignOut}
          className="btn btn-primary"
          type="button"
        >
          SignOut
        </button>
      </div>
    </div>
  )
}
export default Dashboard
