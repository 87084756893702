export function getCurrentDate (broj) {
  var today = new Date()
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0')
  var yyyy = today.getFullYear()
  today = mm + '/' + dd + '/' + yyyy
  const dan = Number(dd) + Number(broj)
  today = yyyy + '-' + mm + '-' + dan
  return today
}
