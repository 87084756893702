import React, { useState } from 'react'

import './Login.css'
function Login () {
  const lg = localStorage.getItem('log')
  if (lg === 'yes') {
    window.location.href = '/'
  }
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const handleSubmit = (event) => {
    event.preventDefault()
    async function getUsers () {
      const url = `https://for-corporates.jamtransfer.com/backend/Login.php?username=${username}&password=${password}`
      try {
        const res = await fetch(url)
        return await res.json()
      } catch (error) {
        console.log(error)
      }
    }
    async function renderUsers () {
      const users = await getUsers()
      if (users.length < 1) {
        alert('Netacni podaci')
      }
      users.forEach((user) => {
        if (user.AuthUserName === username) {
          console.log('yes')
          localStorage.setItem('AuthUserID', user.AuthUserID)
          localStorage.setItem('AuthUserRealName', user.AuthUserRealName)
          localStorage.setItem('AuthLevelID', user.AuthLevelID)
          localStorage.setItem('DriverID', user.DriverID)
          localStorage.setItem('AuthUserMob', user.AuthUserMob)
          localStorage.setItem('AuthUserMail', user.AuthUserMail)
          localStorage.setItem('AuthUserNote', user.AuthUserNote)
          localStorage.setItem('NoteToDriver', user.NoteToDriver)
          localStorage.setItem('AuthUserID', user.AuthUserID)
          localStorage.setItem('Balance', user.Balance)
          localStorage.setItem('log', 'yes')
          window.location.href = '/'
        }
        if (user.AuthUserName !== username) {
          alert('Netacni podaci')
        }
      })
    }

    renderUsers()
  }
  return (
    <div>
     {/*  <form onSubmit={handleSubmit}>
        <div className="imgcontainer">
          <img
            src="https://taxifrom.com/_nuxt/img/logo.07bb2ed.svg"
            alt="Avatar"
            className="avatar"
          />
        </div>

        <div className="container">
          <label htmlFor="uname">
            <b>Username</b>
          </label>
          <input
            type="text"
            placeholder="Enter Username"
            name="uname"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label htmlFor="psw">
            <b>Password</b>
          </label>
          <input
            type="password"
            placeholder="Enter Password"
            name="psw"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit">Login</button>
        </div>
      </form> */}
      <div>
        <main className="form-signin w-100 m-auto">
          <form onSubmit={handleSubmit}>
            <img
              className="mb-4 logoLogin"
              src="https://taxifrom.com/_nuxt/img/logo.07bb2ed.svg"
              alt=""
              width="72"
              height="57"
            />
            <h1 className="h3 mb-3 fw-normal text-center">Please sign in</h1>

            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label htmlFor="floatingInput">Username</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="floatingPassword">Password</label>
            </div>

            <button className="w-100 btn btn-lg btn-primary" type="submit">
              Sign in
            </button>
          </form>
        </main>
      </div>
    </div>
  )
}
export default Login
