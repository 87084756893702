import React from 'react'
// import { useParams } from 'react-router-dom'

function Finished () {
  /*  const { id } = useParams()

  function orderdetails () {
    async function getUsers () {
      const url =
        'https://for-corporates.jamtransfer.com/backend/TransferDetails.php?detailsid=' +
        id
      try {
        const res = await fetch(url)
        return await res.json()
      } catch (error) {
        console.log(error)
      }
    }
    async function renderUsers () {
      const users = await getUsers()
      let html = ''
      users.forEach((user) => {
        const htmlSegment = `<div class="user">
                                   <div>Pax Name ${user.PaxName}</div>
                                   <div>Notes: ${user.FinalNote}</div>
                                   <div>CashIn: ${user.CashIn}</div>
                                </div>`

        html += htmlSegment
      })

      const container = document.querySelector('.container')
      container.innerHTML = html
    }

    renderUsers()
  }
  orderdetails()
  const [vrsta, setVrsta] = useState("1");
  const [nacin, setNacin] = useState("1");

  const handleChange = (event) => {
    setNacin(event.target.value)
  }

  const handleChangeNacin = (event) => {
    setVrsta(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`The name you entered was: `)
  } */

  /*   async function getText (file) {
    const x = await fetch(file)
    const y = await x.text()
    document.getElementById('demo').innerHTML = y
  }
  getText('https://for-corporates.jamtransfer.com/backend/finished.php?id=141368') */

  return (
    <div>
<a href='/datamodel/finished.php?id=141368'>Finish</a>
   {/*    <div className="container"></div>
      <form>
      <h3>Vrsta troska</h3>

        <select value={vrsta} onChange={handleChange}
          className="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
        <h3>Nacin placanja</h3>
        <select  value={nacin} onChange={handleChangeNacin}
          className="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
        >
          <option selected>Open this select menu</option>
          <option value="1">One</option>

        </select>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Amount
          </label>
          <input
            type="number"
            className="form-control form-control-"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Notes
          </label>
          <input
            type="text"
            className="form-control form-control-"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form> */}

    </div>
  )
}

export default Finished
