import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ExtrasList from '../Transfers/ExtrasList'
import ReturnTransfer from '../Transfers/ReturnTransfer'

function TransferDetails () {
  const { id } = useParams()
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState([])
  const username = localStorage.getItem('AuthUserRealName')
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(
      'https://for-corporates.jamtransfer.com/backend/TransferDetails.php?detailsid=' +
        id
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setItems(result)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <span>
        {items.map((item) => (
          <div key={item.detailsid + item.orderid + item.MOrderKey}>
            <table
              key={item.detailsid + item.orderid + item.MOrderKey}
              className="table table-primary"
            >
              <tbody>
                <tr>
                  <td>Order:</td>
                  <td>
                    {item.MOrderKey}-{item.OrderID}
                  </td>
                </tr>
                <tr>
                  <td>Return?</td>
                  <td>
                    <ReturnTransfer orderid={item.OrderID} />
                  </td>
                </tr>
                <tr>
                  <td>Vehicle type:</td>
                  <td>{item.PaxNo} passengers</td>
                </tr>
                <tr>
                  <td>Pickup Name:</td>
                  <td>{item.PickupName}</td>
                </tr>
                <tr>
                  <td>Pickup Address:</td>
                  <td>{item.PickupAddress}</td>
                </tr>
                <tr>
                  <td>FlightNo:</td>
                  <td>
                    {item.FlightNo} {item.FlightTime}
                  </td>
                </tr>
                <tr>
                  <td>Drop Name</td>
                  <td>{item.DropName}</td>
                </tr>
                <tr>
                  <td>Drop Address</td>
                  <td>{item.DropAddress}</td>
                </tr>
                <tr>
                  <td>Drop notes:</td>
                  <td>{item.DropNotes}</td>
                </tr>
                <tr>
                  <td>Pax Name:</td>
                  <td>{item.PaxName}</td>
                </tr>
                <tr>
                  <td>PaxTel:</td>
                  <td>{item.MPaxTel}</td>
                </tr>
                <tr>
                  <td>Extras</td>
                  <td>
                    <ExtrasList detailsid={item.DetailsID} />
                  </td>
                </tr>
                <tr>
                  <td>Drop notes</td>
                  <td>{item.DropNotes}</td>
                </tr>
                <tr>
                  <td>Driver Notes</td>
                  <td>{item.DriverNotes}</td>
                </tr>
                <tr>
                  <td>Naplata {username}</td>
                  <td>{item.DetailPrice} EUR</td>
                </tr>
              </tbody>
            </table>
            <div className="d-grid gap-2 col-6 mx-auto">
              <a href={'/welcome/' + item.PaxName} className="btn btn-primary" type="button">
                Welcome sign
              </a>
              <a href={'/finished/' + item.DetailsID} className="btn btn-primary" type="button">
                Finished
              </a>
              <a className="btn btn-primary" type="button">
                Putni nalog
              </a>
              <a className="btn btn-primary" type="button">
                Receipt
              </a>
            </div>
          </div>
        ))}
      </span>
    )
  }
}

export default TransferDetails
